<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import {
  GetDataMethods,
} from "@/state/helpers";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Banks",
      items: [
        {
          text: "Wesal",
        },
        {
          text: "Banks",
          active: true,
        },
      ],
      i: 20,
      totalRows: 1,
      banksData: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: "Name" },
        { key: "code", sortable: true, label: "Code" },
        { key: "account_name", sortable: true, label: "Account Name" },
        { key: "img", sortable: true, label: "Image" },
        // { key: "action" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     *
     */
    rows() {
      return this.getData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData1();
    this.totalRows = this.banksData.length;
  },
  watch: {
    currentPage: function () {
      this.getData1(this.currentPage);
      this.totalRows = this.banksData.length;
    },
  },
  methods: {
    ...GetDataMethods,

    change_page(v) {
      this.getData1(v);
    },

    getData1(v = 1) {
      this.getData("banks?page=" + v).then((res) => {
        console.log(res.data.data.settings);
        this.banksData = res.data.data.settings;
      });
    },
    /**
     * Search the table data with search input
     */
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Banks</a>
                </template>
                <div class="row mt-4">

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search"
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="banksData" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template v-slot:cell(img)="data">
                      <img :src="'https://wesalserver.com/api/storage/' + data.item.img" alt="Image" width="50">
                    </template>

                    <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template>
                    <template v-slot:cell(action)>
                      <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <!-- <button  v-for="v in i" :key="v" @click="change_page(v)">{{v}}</button> -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
